// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { useDropzone } from 'react-dropzone';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const onDrop = acceptedFiles => {
//     setFiles(acceptedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml, .msg' });

//   const convertFiles = async () => {
//     if (files.length === 0) {
//       Swal.fire({
//         icon: 'error',
//         title: 'No files uploaded',
//         text: 'Please upload files before converting.',
//       });
//       return;
//     }
  
//     setIsLoading(true);
  
//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));
  
//     try {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });
  
//       if (response.ok) {
//         const result = await response.json();
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: result.message,
//         }).then(() => {
//           // After the user closes the success dialog, trigger the file download
//           downloadFile(result.zipPath);
//           setIsLoading(false);
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//       setIsLoading(false);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadFile = async (zipPath) => {
//     try {
//       const response = await fetch(`http://localhost:4008/download/${encodeURIComponent(zipPath)}`, {
//         method: 'GET',
//       });
  
//       if (response.ok) {
//         // Convert the response to a blob
//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = url;
//         link.download = 'converted_files.zip';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     }
//   };
  
//   // Get the first filename
//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div className="container mt-5">
//       <div className="row">
//         <div className="col">
//           <div
//             {...getRootProps()}
//             className="dropzone text-center border border-primary p-4"
//             style={{ cursor: 'pointer' }}
//           >
//             <input {...getInputProps()} />
//             <p className="mb-0 mt-3">Drag & drop .eml or .msg files here, or click to select files</p>
//             <p className="mb-0">Uploaded Files: {firstFileName} {files.length > 1 && `+${files.length - 1}`}</p>
//           </div>
//         </div>
//         <div className="row mt-3">
//           <div className="col">
//             <button
//               className="btn btn-primary btn-block"
//               onClick={convertFiles}
//               disabled={isLoading || files.length === 0}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//           </div>
//           {/* <div className="col">
//             <button
//               className="btn btn-success btn-block"
//               disabled={files.length === 0}
//             >
//               Download Converted Files
//             </button>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;


// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const onDrop = acceptedFiles => {
//     setFiles(acceptedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml, .msg' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }
  
//     setIsLoading(true);
  
//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));
  
//     try {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });
  
//       if (response.ok) {
//         const result = await response.json();
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: result.message,
//         }).then(() => {
//           // After the user closes the success dialog, trigger the file download
//           downloadFile(result.zipPath);
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const downloadFile = async (zipPath) => {
//     try {
//       const response = await fetch(`http://localhost:4008${zipPath}`, {
//         method: 'GET',
//       });
  
//       if (response.ok) {
//         // Convert the response to a blob
//         const blob = await response.blob();
//         // Use FileSaver.js to trigger the download
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     }
//   };
  
  
//   // Get the first filename
//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div className="container mt-5">
//       <div className="row">
//         <div className="col">
//           <div
//             {...getRootProps()}
//             className="dropzone text-center border border-primary p-4"
//             style={{ cursor: 'pointer' }}
//           >
//             <input {...getInputProps()} />
//             <p className="mb-0 mt-3">Drag & drop .eml or .msg files here, or click to select files</p>
//             <p className="mb-0">Uploaded Files: {firstFileName} {files.length > 1 && `+${files.length - 1}`}</p>
//           </div>
//         </div>
//         <div className="row mt-3">
//           <div className="col">
//             <button
//               className="btn btn-primary btn-block"
//               onClick={convertFiles}
//               disabled={isLoading || files.length === 0}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;


import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const onDrop = acceptedFiles => {
    setFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml, .msg' });

  const convertFiles = async () => {
    if (files.length === 0 || isLoading) {
      return;
    }
  
    setIsLoading(true);
  
    // Send files to backend for conversion
    const formData = new FormData();
    files.forEach(file => formData.append('emailFiles', file));
  
    try {
      const response = await fetch('https://viewer.quickcoupondeals.com/convert', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const result = await response.json();
        setDownloadUrl(result.zipPath);
        Swal.fire({
          icon: 'success',
          title: 'Conversion Successful',
          text: 'Your files have been converted successfully.',
        });
      } else {
        throw new Error('Conversion failed');
      }
    } catch (error) {
      console.error('Error converting files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Conversion Error',
        text: 'An error occurred during conversion. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadConvertedFiles = async () => {
    if (!downloadUrl) {
      return;
    }

    // Construct the full download URL
    // const fullUrl = `http://localhost:4008${downloadUrl}`;
    const fullUrl = `https://viewer.quickcoupondeals.com${downloadUrl}`;

    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
      });
  
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();
        // Use FileSaver.js to trigger the download
        saveAs(blob, 'converted_files.zip');
      } else {
        throw new Error('Download failed');
      }
    } catch (error) {
      console.error('Error downloading files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Download Error',
        text: 'An error occurred during download. Please try again.',
      });
    }
  };
  
  // Get the first filename
  const firstFileName = files.length > 0 ? files[0].name : '';

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col">
          <div
            {...getRootProps()}
            className="dropzone text-center border border-primary p-4"
            style={{ cursor: 'pointer' }}
          >
            <input {...getInputProps()} />
            <p className="mb-0 mt-3">Drag & drop .eml or .msg files here, or click to select files</p>
            <p className="mb-0">Uploaded Files: {firstFileName} {files.length > 1 && `+${files.length - 1}`}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <button
              className="btn btn-primary btn-block"
              onClick={convertFiles}
              disabled={isLoading || files.length === 0}
            >
              {isLoading ? 'Converting...' : 'Convert'}
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-success btn-block"
              onClick={downloadConvertedFiles}
              disabled={!downloadUrl}
            >
              Download Converted Files
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
